<template>
  <div class="form-popup" @click="close()">
    <div class="parent-form-container" @click.stop="">
      <b-overlay :show="showOverlay" class="form-container">
        <header>
          <div class="header-part">
            <span> Ajouter un nouveau locataire</span>
            <i class="ik ik-x clos" @click="close()"></i>
          </div>
          <div class="progress-area">
            <div class="step-item">
              <span :class="{ active: step == first }">
                Informations personnelles
              </span>
              <div class="bullet" :class="{ active: step == first }">
                <span v-show="step == first">1</span>
                <div v-show="step > first">
                  <i class="ik ik-check"></i>
                </div>
              </div>
            </div>
            <div class="step-item">
              <span :class="{ active: step == second }"> photo de profil </span>
              <div class="bullet" :class="{ active: step == second }">
                <span v-show="step <= second">2</span>
                <div v-show="step > second">
                  <i class="ik ik-check"></i>
                </div>
              </div>
            </div>
            <div class="step-item">
              <span :class="{ active: step == lastStep }">
                Autres informations
              </span>
              <div class="bullet" :class="{ active: step == lastStep }">
                <span v-show="step <= 2">3</span>
                <div v-show="step > 3">
                  <i class="ik ik-check"></i>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div class="main-form">
          <div class="error-popup" v-show="incompleteData">
            <i class="ik ik-alert-triangle"></i>
            <span>
              {{ customErrorMsg }}
            </span>
          </div>
          <section v-show="step == first" class="basic-data">
            <div class="input-container">
              <div class="input-item">
                <span>Veuillez choisir le mode de création</span>
                <select v-model="selected">
                  <option :value="null" disabled>
                    Cliquez ici pour choisir
                  </option>
                  <option
                    v-for="(mode, cle) in modeCreations"
                    :key="cle"
                    :value="mode.value"
                  >
                    {{ mode.text }}
                  </option>
                </select>
              </div>
              <div class="input-item" v-if="selected == '1'">
                <span>Code utilisateur</span>
                <input
                  type="text"
                  placeholder="ex: Entrez le code de l'utilisateur"
                  v-model="habitant.code"
                />
              </div>
            </div>
            <div
              v-if="selected == '2'"
              style="
                margin: 30px 0 20px;
                width: 97.5%;
                height: 1px;
                background: #eee;
              "
            ></div>
            <div class="input-container" v-if="selected == '2'">
              <div class="input-item">
                <span>Prénom(s)*</span>
                <input
                  type="text"
                  placeholder="ex: Habiba Christelle"
                  v-model="habitant.prenom"
                />
              </div>
              <div class="input-item">
                <span>Nom(s)*</span>
                <input type="text" v-model="name" placeholder="ex: Bouba" />
              </div>
              <div class="input-item">
                <span>Date de naissance*</span>
                <DatePicker
                  v-model="habitant.dateNaiss"
                  placeholder="Selectionnez une date"
                  format="dddd, DD MMMM YYYY"
                  valueType="YYYY-MM-DD"
                  class="w-100"
                  :clearable="false"
                />
              </div>
              <div class="input-item">
                <span>Lieu de naissance*</span>
                <input
                  type="text"
                  placeholder="Entrez le lieu de naissance"
                  v-model="habitant.lieuNaiss"
                />
              </div>
              <div class="input-item">
                <span>Téléphone*</span>
                <input
                  type="number"
                  placeholder="000 000 000 000"
                  v-model="habitant.tel"
                />
              </div>
              <div class="input-item">
                <span>Email</span>
                <input
                  type="email"
                  placeholder="Ex: quelquechose@gmail.com"
                  v-model="habitant.email"
                />
              </div>
            </div>
          </section>
          <section v-show="step == second" class="process-item avatar">
            <div>
              <div class="img-block">
                <!-- <img :src="photo" v-if="photo" alt="image de la cité" /> -->
                <img
                  v-if="this.habitant.avatar"
                  :src="this.habitant.avatar"
                  alt="image de la cité"
                />
                <div class="placeholder-img" v-else>
                  <div>
                    <i class="ik ik-image"></i>
                  </div>
                </div>
                <label>
                  <input
                    type="file"
                    accept="image/*"
                    ref="imageCite"
                    @change="onFile"
                  />
                  Choisir une image
                </label>
              </div>
            </div>
          </section>
          <section v-show="step == lastStep" class="process-item more">
            <div class="more-item">
              <div class="input-item">
                <span>Numéro pièce d'identité *</span>
                <input
                  type="number"
                  placeholder="000 000 000 000"
                  v-model="habitant.cni"
                />
              </div>
              <div class="input-item">
                <span>Profession</span>
                <input
                  type="text"
                  placeholder="ex: Plombier"
                  v-model="habitant.profession"
                />
              </div>
              <div class="input-item">
                <span>Autre numéro téléphone</span>
                <input
                  type="number"
                  placeholder="2e numero de télephone"
                  v-model="habitant.tel2"
                />
              </div>
            </div>
            <div class="more-item">
              <div class="input-item">
                <span>Autre adresse email</span>
                <input
                  type="email"
                  placeholder="*2e adresse email"
                  v-model="habitant.email2"
                />
              </div>
              <div class="input-item">
                <span>Photos CNI</span>
                <div class="semi-container">
                  <div class="cni-card">
                    <label>
                      <input
                        type="file"
                        accept="image/*"
                        ref="imageCite"
                        @change="onFile1"
                      />
                      <img
                        v-if="this.habitant.cniRecto"
                        :src="this.habitant.cniRecto"
                        alt=""
                      />
                      <div v-else>
                        <i class="ik ik-image"></i>
                        <span> Partie recto CNI </span>
                      </div>
                    </label>
                  </div>
                  <div class="cni-card">
                    <label>
                      <input
                        type="file"
                        accept="image/*"
                        ref="imageCite"
                        @change="onFile2"
                      />
                      <img
                        v-if="this.habitant.cniVerso"
                        :src="this.habitant.cniVerso"
                        alt=""
                      />
                      <div v-else>
                        <i class="ik ik-image"></i>
                        <span> Partie verso CNI </span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="form-footer">
          <div class="cancel" @click="close()" v-show="step == 0">
            <OutlinedDarkBtn buttonTitle="Annuler" />
          </div>
          <div class="cancel" @click="back()" v-show="step > 0">
            <OutlinedDarkBtn buttonTitle="Précedent" />
          </div>
          <div class="validate" v-show="step !== lastStep" @click="nextStep()">
            <FullPinkBtn buttonTitle="Suivant" />
          </div>
          <div class="validate" @click="onComplete()" v-show="step == lastStep">
            <FullPinkBtn buttonTitle="Ajouter locataire" :loading=loading></FullPinkBtn>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import FullPinkBtn from "../atomic/fullPinkBtn.vue";
import OutlinedDarkBtn from "../atomic/outlinedDarkBtn.vue";
import notif from "@/plugins/notif.js";
import VueUploadMultipleImage from "vue-upload-multiple-image";
const php = require("phpjs");
export default {
  components: {
    VueUploadMultipleImage,
    FullPinkBtn,
    OutlinedDarkBtn,
    DatePicker,
  },
  props: {},
  data: () => ({
    showOverlay: false,
    loading: false,
    step: 0,
    lastStep: 2,
    first: 0,
    second: 1,
    temoin: false,
    incompleteData: false,
    customErrorMsg: `Veuillez renseigner toutes les informations nécessaires.`,
    options: [
      { text: "Monsieur", value: "M" },
      { text: "Mme", value: "Mme" },
      { text: "Mademoiselle", value: "Mlle" },
    ],
    selected: null,
    modeCreations: [
      { value: "2", text: "Mon locataire n'a pas de compte" },
      { value: "1", text: "Mon locataire a déjà un compte" },
    ],
    time: "",
    isCodeUtilisateur: false,
    name: "",
    habitant: {
      nom: "",
      prenom: "",
      dateNaiss: "",
      lieuNaiss: "",
      profession: "",
      cni: "",
      email: "",
      email2: "",
      tel: "",
      tel2: "",
      tel3: "",
      titre: "M",
      code: "",
      avatar: "",
      cniRecto: "",
      cniVerso: "",
    },
  }),
  methods: {
    close() {
      this.$emit("close");
      this.step = 0;
    },
    back() {
      if (this.step == 0) {
        this.step = 0;
        return;
      }
      this.step--;
      console.log(this.step);
    },
    nextStep() {

      switch (this.step) {
        

        case 0:
          this.step++;
          let re = /\S+@\S+\.\S+/;
          let mailTest = re.test(this.habitant.email);
          // this.habitant.nom = this.name
          if (
            this.name == "" ||
            this.habitant.prenom == "" ||
            this.habitant.email == "" ||
            this.habitant.dateNaiss == "" ||
            this.habitant.lieuNaiss == ""
          ) {
            this.customErrorMsg = `veuillez renseigner toutes les informations nécessaires.`;
            this.incompleteData = true;
            setTimeout(() => {
              this.incompleteData = false;
            }, 2000);
          } else if (!mailTest) {
            this.customErrorMsg = `Entrez une adresse email valide`;
            this.incompleteData = true;
            setTimeout(() => {
              this.incompleteData = false;
            }, 2000);
          } else {
            // validation step 2 si tout est correct
            this.step++;
            console.log(this.habitant.nom);
            console.log(this.name);
          }
          break;
        case 1:
          this.step++;
          break;
        case 2:
          this.step++;
          break;
        default:
          console.log(`Rien ne va plus`);
      }
    },
    onFile(e) {
      const files = e.target.files;
      if (!files.length) return;
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => (this.habitant.avatar = reader.result);
      console.log(this.photo);
    },
    onFile1(e) {
      const files = e.target.files;
      if (!files.length) return;
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => (this.habitant.cniRecto = reader.result);
      console.log(this.photo);
    },
    onFile2(e) {
      const files = e.target.files;
      if (!files.length) return;
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => (this.habitant.cniVerso = reader.result);
      console.log(this.photo);
    },
    //validation formulaire d'ajout/modification d'una annonce
    onComplete() {
      this.showOverlay = true;
      this.loading = true;
      let data = {
        nom: this.name,
        prenom: this.habitant.prenom,
        cni: this.habitant.cni,
        tel: this.habitant.tel,
        tel2: this.habitant.tel2,
        tel3: this.habitant.tel3,
        email: this.habitant.email,
        email2: this.habitant.email2,
        profession: this.habitant.profession,
        titre: this.habitant.titre,
        dateNaiss: this.habitant.dateNaiss,
        lieuNaiss: this.habitant.lieuNaiss,
        code: this.habitant.code,
        avatar: this.habitant.avatar,
        cniRecto: this.habitant.cniRecto,
        cniVerso: this.habitant.cniVerso,
      };
      axios
        .post("locataires", data)
        .then((response) => {
          notif.success(response.message);
          this.$emit("update", response.result);
          this.close()
          this.loading = false;
        })
        .catch((error) => {
          this.customErrorMsg = error.message || "Erreur survenue";
          this.incompleteData = true;
          setTimeout(() => {
            this.incompleteData = false;
          }, 2000);
          this.loading = false;
          //   notif.error(error.message);
          //   this.showOverlay = false;
        });
      //   console.log("data", data);
      //   if (this.action == "edit") {
      //     axios
      //       .put("locataires/" + this.locataire.idLocataire, data)
      //       .then((response) => {
      //         this.resetModal();
      //         notif.success(response.message);
      //         this.$emit("habitantEdited", response.result);
      //       })
      //       .catch((error) => {
      //         notif.error(error.message);
      //         this.showOverlay = false;
      //       });
      //   } else {
      //     axios
      //       .post("locataires", data)
      //       .then((response) => {
      //         this.resetModal();
      //         notif.success(response.message);
      //         this.$emit("habitantAdded", response.result);
      //       })
      //       .catch((error) => {
      //         notif.error(error.message);
      //         this.showOverlay = false;
      //       });
      //   }
    },
  },
  async mounted() {},
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.form-popup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  animation: appear2 0.35s;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  z-index: 1500;
  cursor: pointer;
}
@keyframes appear2 {
  0% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.parent-form-container{
  height: auto;
  max-height: 700px;
  width: 70%;
  max-width: 900px;
  background: white;
  border-radius: 8px;
  cursor: auto;

}
.parent-form-container .form-container {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 700px;
  width: 100%;
  max-width: 900px;
  background: white;
  border-radius: 8px;
  cursor: auto;
}
.form-container header {
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 160px;
  border-bottom: 1px solid #eee;
}
.form-container header .header-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
}
.header-part span {
  font-weight: bold;
  font-size: 1.5rem;
}
.header-part .clos {
  font-size: 1.8rem;
  color: #191c22;
  cursor: pointer;
}
header .progress-area {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
}
.progress-area .step-item {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: auto;
  height: auto;
  user-select: none;
  cursor: pointer;
}
.step-item span {
  font-weight: 500;
  color: #424242;
}
.step-item span.active {
  color: #f5365c;
}
.step-item .bullet {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid #424242;
  overflow: visible;
}
.step-item .bullet span {
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  color: #424242;
}
.step-item .bullet div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: #424242;
}
.step-item .bullet div i {
  color: white;
  font-size: 1.2rem;
}
.bullet.active {
  border: 1px solid #f5365c;
}
.bullet.active span {
  color: #f5365c;
}
.step-item .step .bullet:before,
.step-item .step .bullet:after {
  position: absolute;
  content: "";
  bottom: 11px;
  right: -51px;
  height: 3px;
  width: 44px;
  background: #191c22;
}
.form-container .form-footer {
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  border-top: 1px solid #eee;
}
.form-container .main-form {
  margin-bottom: 50px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100% - 240px);
}
.main-form .error-popup {
  padding-right: 10px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 175px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 350px;
  border-radius: 8px;
  background: white;
  animation: appear 0.3s;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.12);
  z-index: 10000;
}
.error-popup i {
  margin: 0 15px;
  font-size: 1.5rem;
}
.error-popup span {
  text-align: left;
  font-size: 0.9rem;
  font-weight: 500;
}
@keyframes appear {
  0% {
    transform: translateY(30px);
    box-shadow: none;
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.12);
  }
}
.process-item {
  padding: 0 5%;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  transition: 0.3s ease all;
}
.basic-data {
  padding: 30px 5% 0;
  width: 100%;
  height: 100%;
}
.process-item.more {
  padding: 30px 5%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.more .more-item .input-item {
  margin-bottom: 15px;
}
.more-item .input-item .semi-container {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
}
.semi-container .cni-card {
  position: relative;
  width: calc(50% - 7px);
  height: 100px;
  border-radius: 8px;
  border: 1px solid #eee;
}
.cni-card label {
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
}
.cni-card label input {
  display: none;
}
.cni-card label img {
  margin: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.cni-card label div {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  /* background: teal; */
}
.cni-card label div i {
  font-size: 1.4rem;
  color: #5f5f5f;
}
.cni-card label div span {
  margin-top: 5px;
}
.input-container {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 20px));
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  width: 100%;
  height: auto;
}
.title {
  font-weight: 600;
}
.input-container .input-item {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}
.input-item > span {
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 600;
}
.input-item input,
.input-item select {
  padding: 0 5px;
  height: 45px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
  outline: none;
  background: transparent;
}
/* .input-item select .batiment-option {
}
.batiment-option .bat-name {
} */
.input-item input::placeholder {
  font-size: 0.85rem;
}
.input-container.radio label,
.input-container.index label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45%;
  background: transparent;
  background: #f0f0f0;
  border-radius: 8px;
}
.input-container.radio label.check,
.input-container.index label.check {
  background: #191c22;
  color: white;
}
.input-container.radio label input {
  display: none;
}
.process-item.location {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.process-item.location .input-container {
  display: flex;
  flex-direction: column;
}
.process-item.configuration {
  padding-top: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.process-item.configuration .config-group {
  display: flex;
  align-items: center;
  height: auto;
  width: 85%;
}
.process-item.configuration .config-group .barre {
  margin: 0 20px 15px;
  height: 50%;
  width: 2px;
  background: #eee;
}
.process-item.configuration .barre-h {
  margin: 30px 0;
  height: 1px;
  width: calc(80% + 42px);
  background: #eee;
}
.process-item.configuration .other-config-container {
  height: auto;
  width: 45%;
}
.other-config {
  margin-top: 20px;
  display: flex;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
  height: 50px;
}
.other-config select {
  height: 100%;
  width: calc(100% - 100px);
  border-radius: 8px 0 0 8px;
  border: none;
}
.other-config input {
  height: 100%;
  width: 100px;
  border-radius: 0 8px 8px 0;
  border: none;
}
.process-item.configuration .config-item {
  margin-bottom: 15px;
  padding: 0 5px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 47%;
  border-radius: 8px;
  border: 1px solid #eee;
}
.config-item .title-item {
  font-weight: 500;
}
.config-item .config-controller {
  padding-left: 15px;
  display: flex;
  align-items: center;
  height: 100%;
  width: auto;
}
.config-controller .control-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  width: 40px;
  background: #f5f5f5;
  font-size: 1.3rem;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  color: #424242;
  transition: all 0.2s ease;
}
.config-controller .control-btn:active {
  transform: scale(0.96);
}
.config-controller .item-value {
  margin: 0 10px;
}
.process-item.end {
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.process-item.end .title {
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
}
.process-item.end p {
  margin-top: 1.2rem;
  text-align: center;
  width: 80%;
  max-width: 600px;
  color: #424242;
}
.img-block {
  margin-top: 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.img-block img {
  display: inline-block;
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
}
.img-block label {
  margin-top: 20px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: auto;
  border: 1px solid #eee;
  font-size: 1rem;
  border-radius: 8px;
}
.img-block label input {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: transparent;
  display: none;
}
.img-block .placeholder-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
}
.placeholder-img div {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 1px solid #eee;
}
.placeholder-img i {
  font-size: 1.8rem;
}
.cite-form .footer-form {
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10%;
  min-height: 70px;
  border-top: 1px solid #eee;
}
.process-item.avatar {
  justify-content: center;
  align-items: center;
  height: 200px;
}
</style>
