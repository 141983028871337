<template>
  <div class="container-fluid">
    <!--page header start -->
    <div class="row">
      <div class="col-md-12">
        <div class="mb-2 clearfix">
          <div class="display-options headder-hbt-page" id="displayOptions">
            <SearchForm v-model="search" :placeholder="placeholderInput" />
            <div class="float-md-right d-flex">
              <paginatorTop
                :offset="offset"
                :libelle="$t('data.habitants')"
                :total="totalItems"
                :limit="perPage"
                :page="currentPage"
                @pageChanged="
                  (page) => {
                    currentPage = page;
                  }
                "
                @limitChanged="
                  (limit) => {
                    perPage = limit;
                  }
                "
                class="mr-2 d-flex justify-content-center align-items-center"
              />
              <!-- <btnAdd
                :disabled="!canCreateLocataire"
                :message="$t('data.habitant_form_ajouter_habitant')"
                v-b-modal.habitantForm
                ref="buttonAdd"
              />  -->
              <btnAdd
                :disabled="!canCreateLocataire"
                :message="$t('data.habitant_form_ajouter_habitant')"
                @click="ajouterLocataireVisible = true"
                ref="buttonAdd"
              />
            </div>
          </div>
        </div>
        <p class="rappel">
          {{ $t("data.possessions_actuelle") }}
          <span style="color: #f5365c;"
            >{{ totalItems }} {{ $t("data.habitants") }}.</span
          >
        </p>
        <div class="separator mb-20"></div>
        <b-overlay :show="showOverlay" rounded="sm">
          <b-alert
            variant="info"
            class="text-center"
            show
            v-if="!habitants.length"
          >
            <i class="fa fa-exclamation-triangle fa-3x"></i> <br />
            <span class="h4 d-inline-flex ml-2">{{
              $t("data.habitant_form_pas_de_habitant")
            }}</span>
          </b-alert>
          <div class="hbt-container" v-else>
            <!-- <b-col  xs="12" md="4" xl="3" v-for="locataire in habitants" :key="locataire.idLocataire"> 
                            <div class="card">
                                <div class="text-center">
                                    <div class="card-body pb-0">
                                        <b-avatar :src="locataire.avatar" size="8em" />
                                    </div>
                                    <div class="card-body mt-0 px-0">
                                        <h4 class="truncate card-title blue">{{ locataire.nomLocataire + ' ' + locataire.prenomLocataire }}</h4>
                                        <h6 class="truncate card-subtitle text-muted">{{ locataire.tel }}</h6>
                                        <h6 class="truncate small card-subtitle text-muted">{{ locataire.profession }}</h6>
                                        <div class="mt-3 d-flex justify-content-center">
                                            <b-button-group>
                                                <b-button v-if="canSeeDetailsHabitant" size="sm" v-b-tooltip.top="$t('data.cite_tooltip_details')" @click.prevent="showDetails(locataire)"><i class="ik f-16 ik-eye"></i></b-button>
                                                <b-button v-if="canUpdateHabitant" size="sm" v-b-tooltip.top="$t('data.cite_tooltip_editer')" @click.prevent="editHabitant(locataire)"><i class="ik f-16 ik-edit"></i></b-button>
                                                <b-button v-if="canDeleteHabitant" size="sm" v-b-tooltip.top="$t('data.cite_tooltip_supprimer')" @click.prevent="removeHabitant(locataire)"><i class="ik f-16 ik-trash-2"></i></b-button>
                                            </b-button-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-col> -->
            <div
              class="hbt-card"
              v-for="locataire in habitants"
              :key="locataire.idLocataire"
            >
              <div class="hbt-top">
                <div class="avatar-user">
                  <img :src="locataire.avatar" alt="user" />
                </div>
                <span class="user-name">
                  {{ locataire.nomLocataire + " " + locataire.prenomLocataire }}
                </span>
                <span class="user-tel">
                  Tel {{ locataire.tel }} <br />
                  {{ locataire.profession }}
                </span>
              </div>
              <div class="action-container">
                <button
                  v-if="canSeeDetailsHabitant"
                  @click.prevent="showDetails(locataire)"
                >
                  <i class="ik f-16 ik-eye"></i>
                </button>
                <div class="barre"></div>
                <button
                  v-if="canUpdateHabitant"
                  @click.prevent="editHabitant(locataire)"
                >
                  <i class="ik f-16 ik-edit"></i>
                </button>
                <div class="barre"></div>
                <button
                  v-if="canDeleteHabitant"
                  @click.prevent="removeHabitant(locataire)"
                >
                  <i class="ik f-16 ik-trash-2"></i>
                </button>
              </div>
            </div>
            <!-- <b-col xs="12" md="6" xl="4" v-for="locataire in habitants" :key="locataire.idLocataire"> 
                            <div class="card" style="border-radius: 10px">
                                <div class="d-flex p-3" >
                                    <div class="mr-3 ">
                                        <div class="rounded-circle overflow-hidden d-flex justify-content-center">
                                            <b-avatar :src="locataire.avatar" size="5rem" />
                                        </div>
                                        <p class='text-center text-muted mt-2'>{{ locataire.profession }}</p>
                                    </div>
                                    <div class="card-body mt-0 px-0">
                                        <h4 class="card-title" style="white-space: wrap;">{{ locataire.nomLocataire + ' ' + locataire.prenomLocataire }}</h4>
                                        <h6 class="truncate card-subtitle text-muted">Tel: {{ locataire.tel }}</h6>
                                        <div class="d-flex justify-content-end" style="margin-top: 30px">
                                            <b-button-group >
                                                <b-button v-if="canSeeDetailsHabitant" size="sm" v-b-tooltip.top="$t('data.cite_tooltip_details')" @click.prevent="showDetails(locataire)"><i class="ik f-16 ik-eye"></i></b-button>
                                                <b-button v-if="canUpdateHabitant" size="sm" v-b-tooltip.top="$t('data.cite_tooltip_editer')"><i class="ik f-16 ik-edit"></i></b-button>
                                                <b-button v-if="canDeleteHabitant" size="sm" v-b-tooltip.top="$t('data.cite_tooltip_supprimer')"><i class="ik f-16 ik-trash-2"></i></b-button>
                                            </b-button-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-col> -->
          </div>
          <paginator
            v-if="totalItems > 5"
            hr="top"
            :offset="offset"
            :total="totalItems"
            :limit="perPage"
            :page="currentPage"
            @pageChanged="
              (page) => {
                currentPage = page;
              }
            "
            @limitChanged="
              (limit) => {
                perPage = limit;
              }
            "
          />
        </b-overlay>
      </div>
    </div>

    <!-- MODALE POUR AFFICHER LES DETAILS D'UN HABITANT -->
    <div
      v-if="locataire"
      class="modal fade edit-layout-modal"
      id="editLayoutItem"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editLayoutItemLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editLayoutItemLabel">
              {{ $t("data.habitant_form_dossier_de") }}
              <b>{{ locataire.nomLocataire }}</b>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pt-2">
            <details-habitant
              :idLocataire="locataire.idLocataire"
              @change="getHabitants"
            />
          </div>
        </div>
      </div>
    </div>

    <!--MODAL POUR AJOUTER OU MODIFIER UN LOGEMENT-->
    <b-modal
      id="habitantForm"
      ref="habitant-form"
      size="lg"
      :title="action == 'edit' ? 'Edition de locataire' : title"
      ok-title="Fermer"
      ok-only
      ok-variant="secondary"
      no-close-on-backdrop
      hide-header-close
    >
      <div>
        <habitant-form
          :locataire="locataire"
          @habitantEdited="editedHabitant"
          @habitantAdded="addedHabitant"
          :action="action"
        />
      </div>
    </b-modal>
    
    <AjouterLocataire v-show="ajouterLocataireVisible" @close="ajouterLocataireVisible = false" @update="(data) => addedHabitant(data)"/>
  </div>
</template>

<script>
import HabitantForm from "@/views/gestion-immobiliere/habitants/HabitantForm.vue";
import DetailsHabitant from "@/views/gestion-immobiliere/habitants/DetailsHabitant.vue";
import SearchForm from "@/components/parts/SearchForm.vue";

const php = require("phpjs");
import { mapGetters } from "vuex";
import AjouterLocataire from '../../../components/form/ajouterLocataire.vue';

export default {
  name: "list-habitants",
  components: {
    HabitantForm,
    DetailsHabitant,
    SearchForm,
    AjouterLocataire,
  },
  data: () => ({
    action: "add",
    search: null,
    showOverlay: true,
    ajouterLocataireVisible: false,
    placeholderInput: "Rechercher un locataire",

    currentPage: 1,
    perPage: 10,
    totalItems: 0,

    locataire: null,
    habitants: [],
    trueHabitants: [],
    title: "Ajouter un locataire",
  }),

  watch: {
    search(value) {
      this.habitants = !php.empty(value)
        ? this.trueHabitants.filter(
            (elt) =>
              elt.nomLocataire.toLowerCase().includes(value.toLowerCase()) ||
              elt.prenomLocataire.toLowerCase().includes(value.toLowerCase())
          )
        : this.trueHabitants;
    },
    perPage() {
      this.getHabitants();
    },
    currentPage() {
      this.getHabitants();
    },
  },
  computed: {
    ...mapGetters(["hasAccess"]),
    offset() {
      return this.currentPage * this.perPage - this.perPage;
    },
    canCreateLocataire() {
      return this.hasAccess("add_locataire");
    },
    canSeeDetailsHabitant() {
      return this.hasAccess("view_details_locataire");
    },
    canUpdateHabitant() {
      return this.hasAccess("edit_locataire");
    },
    canDeleteHabitant() {
      return this.hasAccess("delete_locataire");
    },
  },

  beforeMount() {
    this.getHabitants();
  },
  mounted() {
    this.$root.$on("show-detail-occupation", (occupation) => {
      $("#editLayoutItem").modal("hide");
    });
  },
  methods: {
    editHabitant(locataire) {
      this.action = "edit";
      this.locataire = locataire;
      this.$bvModal.show("habitantForm");
    },

    //traitement de l'évènement émis d'ajout d'un locataire
    addedHabitant(habitant) {
      this.habitants = [...this.habitants, habitant];
      this.trueHabitants = [...this.trueHabitants, habitant];
      this.totalItems = this.totalItems + 1;
      this.$bvModal.hide("habitantForm");
    },
    editedHabitant(locataire) {
      this.habitants = this.habitants.map((elt) => {
        if (elt.idLocataire == locataire.idLocataire) {
          elt = locataire;
        }
        return elt;
      });
      this.locataire = null;
      this.action = "";
      this.$bvModal.hide("habitantForm");
    },

    /**
     * Affiche le modal de création d'un locataire directement au chargement de la page
     * ceci est utilisé lorsqu'on est arrivé ici en provenant de la homepage
     */
    autoAddTarget() {
      const formHabitant = this.$route.query.formHabitant || null;
      if (formHabitant) {
        this.$refs["habitant-form"].show();
        window.history.replaceState({}, "", window.location.href.split("?")[0]);
      }
    },
    //recupération de la liste des locataires
    getHabitants() {
      this.showOverlay = true;
      axios
        .get(`locataires?size=${this.perPage}&page=${this.currentPage - 1}`)
        .then((response) => response.result || [])
        .then((result) => {
          this.habitants = this.trueHabitants = result.items;
          this.totalItems = result.totalItems;
          console.log(this.habitants);

          this.autoAddTarget();
          this.showOverlay = false;
          this.autoDetailsTarget();
        });
    },
    updateHabitant(habitant) {
      console.log("habitant", habitant);
    },
    removeHabitant(locataire) {
      App.confirm(
        `Voulez vous vraiment supprimer l'habitant " <b>${locataire.nomLocataire}</b> " ?`,
        {
          confirm: () => {
            axios
              .delete(`locataires/${locataire.idLocataire}`)
              .then((response) => {
                this.habitants = this.habitants.filter(
                  (elt) => elt.idLocataire != locataire.idLocataire
                );
                this.trueHabitants = this.trueHabitants.filter(
                  (elt) => elt.idLocataire != locataire.idLocataire
                );
                this.totalItems = this.totalItems - 1;
                return App.notifySuccess(response.message);
              })
              .catch((error) => {
                return App.notifyError(error.message);
              });
          },
        }
      );
    },
    showDetails(locataire) {
      this.locataire = locataire;
      $(".modal-backdrop").remove();
      setTimeout(() => {
        $("#editLayoutItem").modal("show");
        $("#editLayoutItem").on("hide.bs.modal", (e) => {
          this.locataire = null;
        });
        $("#editLayoutItem").on("hidden.bs.modal", (e) => {
          this.locataire = null;
        });
      }, 100);
    },
    showOccupation() {
      $("#editLayoutItem").modal("hide");
    },
    /**
     * Affiche automatiquement les details d'un logement au chargement
     * ceci est utilise dans le cas où on a cliqué sur le batiment en etant sur le details d'une cité
     */
    autoDetailsTarget() {
      const target = this.$route.query.target || null;
      if (target) {
        const locataire = this.habitants.filter(
          (elt) => elt.idLocataire == target
        )[0];
        if (locataire) {
          this.showDetails(locataire);
          window.history.replaceState(
            {},
            "",
            window.location.href.split("?")[0]
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.resize {
  width: 2em;
  height: 2em;
  margin-right: 1px;
}
.headder-hbt-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
}
p.rappel {
  font-size: 1.2rem;
  margin-top: 40px;
  font-weight: 500;
  color: #191c22;
}
.hbt-card {
  padding: 10px 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  height: 18rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
}
.hbt-card .hbt-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
}
.hbt-top .avatar-user {
  margin-bottom: 1rem;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  border: 5px solid #f5f5f5;
}
.avatar-user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.hbt-top .user-name {
  margin-bottom: 0.6rem;
  font-weight: bold;
  color: #191c22;
  font-size: 1.1rem;
  text-align: center;
}
.hbt-top .user-tel {
  color: #5c5c5c;
  font-size: 0.95rem;
}
.hbt-card .action-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.action-container .barre {
  margin: 0 10px;
  height: 40%;
  width: 2px;
  background: #efefef;
}
.action-container button {
  background: transparent;
  border: none;
}
.hbt-container {
  display: grid;
  grid-template-columns: repeat(6, calc(16.6666% - 20px));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 1700px) {
  .hbt-container {
    grid-template-columns: repeat(5, calc(20% - 20px));
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
}
@media only screen and (max-width: 1650px) {
  .hbt-container {
    grid-template-columns: repeat(4, calc(25% - 20px));
  }
}
@media only screen and (max-width: 1200px) {
  .hbt-container {
    grid-template-columns: repeat(3, calc(33.3333% - 20px));
  }
}
@media only screen and (max-width: 650px) {
  .hbt-container {
    grid-template-columns: repeat(2, calc(50% - 20px));
  }
}
</style>
